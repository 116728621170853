.App
{
    text-align: center;
}

.App-logo
{
    pointer-events: none;
    width: 180px;
    position: absolute;
    top: 15px;
    left: 15px;
    z-index: 500;
}

.App-header
{
    position: absolute;
    top: 15px;
    right: 15px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    font-size: calc(10px + 2vmin);
    color: white;
    z-index: 500;
    text-align: left;
}

.App-link
{
    color: #61dafb;
}
