body {
  margin: 0;
  font-family: AntipastoPro-DemiBold, sans-serif;
    Font-weight: normal;
    font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input,
select,
textarea,
button
{
    font-family: AntipastoPro-DemiBold, sans-serif;
    padding: 6px;
    border-radius: 6px;
    border: 1px solid #ccc;
    box-shadow: 0 0 12px rgba(0,0,0,.1);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

sub {
  vertical-align: sub;
  font-size: smaller;
}

strong {
    font-weight: bold;
}
