
.user-link {
    padding: 0px;
    text-align: right;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
    margin-bottom: 20px;
}

.user-link li {
    display: inline-block;
}

.user-link li a {
    padding: 8px 15px;
    background-color: white;
    line-height: 28px;
    border-radius: 6px;
    box-shadow: 0px 0px 10px #999;
    margin-left: 20px;
    text-decoration: none;
    color: #000;
}

.user-link li a:hover,
.user-link li a.active {
    background-color: #00d4fa;
    color: #fff;
}

.user-link li a.logout {
    background-color: #992c2c;
    color: #fff;
}

.user-box {
    background-color: #00d4fa;
    box-shadow: 0px 0px 10px #999;
    border-radius: 6px;
    padding: 35px 15px 15px 15px;
    position: relative;
}

.user-box .form-group
{
    margin-bottom: 10px;
}

.user-box label
{
    width: 100px;
    font-size: 14px;
    display: inline-block;
    text-align: right;
    padding-right: 10px;
}

.user-box input
{
    border: 0;
    padding: 6px;
    border-radius:4px;
}

.user-box button,
.user-welcome button
{
    background: #fff;
    color: #000;
    border: 0;
    line-height: 28px;
    border-radius: 6px;
    margin-left: 20px;
    cursor: pointer
}

.user-box button.form-button
{
    margin-left: 110px;
}

.user-box button:hover
{
    background-color: #999;
    color: #fff;
}

.user-box-profile
{
    background-color: #fff;
    box-shadow: 0px 0px 10px #999;
    border-radius: 6px;
    padding: 15px 15px 15px 15px;
    position: relative;
    width: 800px;
}

.user-box-profile h3
{
    color: #00d4fa;
    font-size: 20px;
    font-family: 'Roboto';
    margin-bottom: 10px;
}

.user-box-profile h4
{
    color: #992c2c;
    font-size: 16px;
    font-family: 'Roboto';
    margin-bottom: 5px;
    font-weight: bold;
}

.user-box-profile .fields
{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.user-box-profile .half
{
    width: 49%;
    display: inline-block;
    vertical-align: top;
}

.user-box-profile .row
{
    background: #f4f4f4;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
}

.user-box-profile label
{
    font-size: 14px;
    padding-right: 10px;
    color: #000;
    display: inline-block;
}

.user-box .close,
.user-box-profile .close
{
    text-decoration: none;
    background: #fff;
    color: #00d4fa;
    border-radius: 5px;
    position: absolute;
    top: 10px;
    right: 15px;
    padding: 0 6px;
    font-size: 20px;
}

.user-box-profile .close
{
    background: #eee;
}

.user-box .close:hover,
.user-box-profile .close:hover
{
    color: red;
}

.user-box-profile button
{
    background: #00d4fa;
    color: #fff;
}

.user-box-profile .diseases
{
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
}

.user-box-profile .diseases label
{
    width: 40%;
    margin-bottom: 10px;
}

.user-box-profile .diseases label:last-child
{
    width: 80%;
}

.form-check
{
    padding-left: 110px;
}

.form-check label
{
    width: auto;
    vertical-align: middle;
    padding-left: 5px;
}

.alert,
.message
{
    font-size: 14px;
    text-align: center;
    padding: 10px;
    background: #66e6fc;
    margin-bottom: 10px;
    border-radius: 6px;
}

.form-text
{
    font-size: 12px;
}

.text-danger
{
    color: #942626;
    text-align: right;
    padding-bottom: 5px;
}
