.Marker{
    background-color: grey;
    width: 34px;
    height: 34px;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    box-shadow: 0px 0px 30px red;
    color: #fff;
    font-size: 12px;
    animation-duration: 4s;
    animation-iteration-count: infinite;
    margin-top: -17px;
    margin-left: -17px;
    cursor: pointer;
}

.Marker.p1{
    background-color: #609127;
    box-shadow: 0px 0px 30px #609127;
    animation-name: markerp1;
}

.Marker.p2{
    background-color: #d1e851;
    box-shadow: 0px 0px 30px #d1e851;
    animation-name: markerp2;
}

.Marker.p3{
    background-color: #e8bf42;
    box-shadow: 0px 0px 30px #e8bf42;
    animation-name: markerp3;
}

.Marker.p4{
    background-color: #d98383;
    box-shadow: 0px 0px 30px #d98383;
    animation-name: markerp4;
}

.Marker.p5{
    background-color: #c04242;
    box-shadow: 0px 0px 30px #c04242;
    animation-name: markerp5;
}

@keyframes marker {
    from {box-shadow: 0px 0px 30px #609127;}
    50% {box-shadow: 0px 0px 10px #609127;}
    to {box-shadow: 0px 0px 30px #609127;}
}

@keyframes markerp1 {
    from {box-shadow: 0px 0px 30px #609127;}
    50% {box-shadow: 0px 0px 10px #609127;}
    to {box-shadow: 0px 0px 30px #609127;}
}

@keyframes markerp2 {
    from {box-shadow: 0px 0px 30px #d1e851;}
    50% {box-shadow: 0px 0px 10px #d1e851;}
    to {box-shadow: 0px 0px 30px #d1e851;}
}

@keyframes markerp3 {
    from {box-shadow: 0px 0px 30px #e8bf42;}
    50% {box-shadow: 0px 0px 10px #e8bf42;}
    to {box-shadow: 0px 0px 30px #e8bf42;}
}

@keyframes markerp4 {
    from {box-shadow: 0px 0px 30px #d98383;}
    50% {box-shadow: 0px 0px 10px #d98383;}
    to {box-shadow: 0px 0px 30px #d98383;}
}

@keyframes markerp5 {
    from {box-shadow: 0px 0px 30px #c04242;}
    50% {box-shadow: 0px 0px 10px #c04242;}
    to {box-shadow: 0px 0px 30px #c04242;}
}

.Panel {
    position: absolute;
    bottom: 20px;
    left: 10px;
    text-align: left;
    z-index: 1100;
}

.Panel h2 {
    font-size: 12px;
    text-transform: uppercase;
    padding: 0px;
}

.Panel .Buttons,
.Panel .Scale {
    margin-bottom: 15px;
}

.Panel .ButtonsIn {
    display: flex;
    align-items: center;
}

.Panel .StyleButton {
    padding: 5px;
}

.Panel .StyleButton:hover {
    background: #eee;
    transition: .3s;
}

.Panel .StyleButton img {
    width: 22px;
    display: inline-block;
    vertical-align: top;
}

.Panel .ButtonsList {
    display: none;
}

.Panel .ButtonsList.show {
    display: block;
}

.Panel button {
    background: #fff;
    border-radius: 5px;
    padding: 6px 10px;
    margin-right: 10px;
    outline: none;
    cursor: pointer;
    border: 1px solid #bbb;
    box-shadow: 0px 0px 10px #bbb;
}

.Panel button:hover {
    background: #eee;
    transition: .3s;
}

.Panel button.active {
    background: green;
    color: #fff;
    border: 1px solid green;
}

.Panel .Scale {
    display: inline-block;
    background: rgba(255,255,255,.95);
    padding: 10px 15px;
    border-radius: 6px;
    box-shadow: 0px 0px 10px #bbb;
    position: relative;
}

.Panel .Scale h2 {
    font-size: 12px;
    padding: 5px 0 10px 0;
}

.Panel .ScaleIn span {
    font-size: 14px;
    padding: 5px 0px;
}

.Panel .ScaleIn span.block {
    display: inline-block;
    width: 16px;
    height: 40px;
    margin-right: 10px;
}

.Panel .ScaleIn span.name {
    display: none;
}

.Panel .Scale.show .ScaleIn span.name {
    display: inline-block;
    text-transform: uppercase;
}

.Panel .ScaleIn div.row {
    display: flex;
    align-items: center;
}

.Panel .ScaleIn div.row:first-of-type span.block {
    border-radius: 8px 8px 0 0;
}

.Panel .ScaleIn div.row:last-of-type span.block {
    border-radius: 0 0 8px 8px;
}

.Panel .ScaleIn .row span img {
    width: 34px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
}

.Panel .ScaleIn div.arrows{
    position: absolute;
    bottom: 16px;
    right: -30px;
    background: rgba(255,255,255,.95);
    box-shadow: 4px 0px 8px #bbb;
    border-radius: 0 4px 4px 0;
}

.Panel .ScaleIn .arrow {
    text-transform: uppercase;
    cursor: pointer;
    display: inline-block;
}

.Panel .ScaleIn .arrow:hover {
    opacity: .5;
    transition: .3s;
}

.Panel .ScaleIn .arrow img {
    width: 18px;
    vertical-align: top;
    padding: 4px 6px;
}

.Panel .Scale .ScaleIn span.arrow.arrow-left {
    display: none;
}

.Panel .Scale.show .ScaleIn span.arrow.arrow-right {
    display: none;
}

.Panel .Scale.show .ScaleIn span.arrow.arrow-left {
    display: inline-block;
}

.GeolocateControl {
    position: absolute;
    bottom: 30px;
    right: 10px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
}

.NavigationControl {
    position: absolute;
    bottom: 70px;
    right: 10px;
    border-radius: 50%;
}

.App .mapboxgl-popup {
    margin-top: 10px;
    z-index: 2000;
}

.App .mapboxgl-popup-content {
    border-radius: 10px;
    padding: 10px;
}

.App .mapboxgl-popup-close-button {
    font-size: 26px;
    line-height: 26px;
    border: 1px solid #eee;
    border-radius: 5px;
    margin: 10px;
    padding: 0px 6px;
}

.popup {
    border-radius: 20px;
    padding: 10px;
    width: 300px;
}

.popup img {
    max-width: 60px;
}

.popup h3 {
    font-size: 30px;
    padding: 10px 0;
    font-weight: bold;
}

.popup .reading {
    margin-top: 10px;
    background: #eee;
    border-radius: 6px;
    padding: 10px;
    text-align: left;
}

.popup .reading h5 {
    display: flex;
    width: 100%;
    padding: 5px 0;
    justify-content: space-between;
}

.popup .reading h5 span span {
    font-weight: bold;
}

.popup .bottom {
    padding-top: 15px;
    font-size: 12px;
    color: #999;
    font-weight: normal;
    line-height: 130%;
}

.favourites {
    position: absolute;
    bottom: 0;
    left: 100px;
    right: 100px;
    z-index: 1000;
}

.favouriteButton {
    position: absolute;
    left: 10px;
    top: 10px;
}

.chartButton {
    position: absolute;
    left: 10px;
    top: 45px;
}

.favouriteButton .heart,
.chartButton .chart {
    border: 1px solid #eee;
    border-radius: 5px;
    background: #fff;
    padding: 5px;
    outline: none;
}

.favouriteButton .heart:hover,
.chartButton .chart:hover {
    background: #eee;
    cursor: pointer;
}

.favouriteButton .heart img,
.chartButton .chart img {
    width: 18px;
    vertical-align: top;
}

.favouriteBlock {
    display: inline-block;
    background: #fff;
    padding: 10px;
    margin: 10px;
    background: rgba(255,255,255,.95);
    border-radius: 6px;
    box-shadow: 0px 0px 10px #bbb;
    color: #444;
    font-size: 12px;
    border-width: 5px;
    border-style: solid;
}

.favouriteBlock:hover {
    background: rgba(235,235,235,.95);
    cursor: pointer;
}

.favouriteBlock h3 {
    font-size: 24px;
    line-height: 130%;
}

.quote {
    padding: 10px;
    border-radius: 6px;
}
